/* eslint-disable max-len */
import * as React from 'react'
import { IIconProps } from './types'

export const HandIcon = (props: IIconProps): React.ReactElement => (
  <svg viewBox="0 0 512 512" {...props}>
    <title>Hand</title>
    <path d="M402.034 77.436a241.872 241.872 0 00-56.135-75.499 7.504 7.504 0 00-10.595.531 7.503 7.503 0 00.531 10.595 226.83 226.83 0 0152.647 70.806c1.79 3.767 6.277 5.324 9.993 3.559a7.5 7.5 0 003.559-9.992zM359.714 88.616a199.058 199.058 0 00-36.707-49.369 7.502 7.502 0 10-10.51 10.705 183.994 183.994 0 0133.938 45.645 7.5 7.5 0 006.647 4.012c5.564 0 9.279-5.958 6.632-10.993zM72.529 318.782a226.994 226.994 0 01-29.8-32.818 226.837 226.837 0 01-22.845-37.987 7.501 7.501 0 00-13.552 6.434 241.86 241.86 0 0056.134 75.497 7.468 7.468 0 005.029 1.938c6.807-.001 10.144-8.443 5.034-13.064zM95.87 281.895a184.026 184.026 0 01-33.937-45.646 7.501 7.501 0 00-13.278 6.982 199.087 199.087 0 0036.707 49.37 7.5 7.5 0 0010.508-10.706z" />
    <path d="M499.781 371.92l-13.367-17.98c1.904-7.314 8.028-25.754 4.968-51.339-3.324-27.768-11.94-32.114-14.678-48.103-6.713-39.192 11.373-92.947 15.066-103.325.155-.437.247-.707.277-.803 7.899-24.969 13.117-41.466-12.029-53.564-22.497-10.822-46.173-1.213-60.314 24.482-.866 1.574-19.008 34.933-26.809 80.441l-92.57-124.504a7.498 7.498 0 00-10.495-1.544 7.5 7.5 0 00-1.544 10.494l103.578 139.309c4.091 5.502 12.838 3.055 13.486-3.765 4.912-51.683 26.576-91.522 27.497-93.194 3.853-7.006 18.18-29.018 40.668-18.198 14.204 6.834 11.611 10.803 4.122 35.816-5.497 15.446-22.878 69.093-15.72 110.888 3.435 20.051 11.442 21.223 14.568 47.355 1.252 10.46.869 21.125-1.127 31.831-.482 2.363-3.211 13.553-13.584 26.015a7.502 7.502 0 00.967 10.564c3.189 2.655 7.919 2.21 10.564-.967a90.499 90.499 0 003.935-5.084l10.503 14.126c6.101 8.205 4.388 19.843-3.815 25.942l-15.78 11.733c-5.794 4.308-2.714 13.52 4.48 13.52 4.236 0 4.927-2.383 20.251-13.215 14.841-11.036 17.938-32.09 6.902-46.931zM450.504 441.011a7.497 7.497 0 00-10.494-1.544l-72.417 53.844c-8.293 6.165-20.053 4.433-26.217-3.855-2.995-3.154-13.062-22.889-37.872-30.259-44.591-13.246-59.616-46.662-68.075-55.748a7.502 7.502 0 00-12.038 8.952c7.916 8.427 25.751 46.297 75.842 61.176 19.255 5.72 26.879 21.366 30.107 24.829 11.08 14.901 32.252 18.061 47.205 6.943l72.418-53.844a7.501 7.501 0 001.541-10.494zM102.826 250.247l-.048-.065.048.065zM102.755 250.152c-.002-.003-.002-.003 0 0z" />
    <path d="M317.375 203.536L218.852 71.023v-.001c-7.985-10.737-5.469-26.482 6.624-33.883 10.155-6.217 23.866-3.343 31.216 6.541l10.675 14.358a7.5 7.5 0 0010.494 1.544 7.5 7.5 0 001.544-10.494L268.73 34.73c-19.083-25.665-59.451-18.936-68.091 12.115-21.714-23.89-61.463-12.786-66.591 19.229-1.479 9.233.392 18.453 5.278 26.291-31.77-.685-50.429 35.775-31.132 61.726l11.734 15.782c-32.355-.346-49.896 36.267-30.972 61.72l13.804 18.566-.005-.006.023.03-.016-.021.232.312-.168-.226.208.28a.022.022 0 00.004.005l99.43 133.73a7.484 7.484 0 006.025 3.025c6.073 0 9.723-6.987 6.014-11.976C77.984 191.69 115.436 242.065 100.994 222.641c-12.373-16.643.388-39.717 20.698-37.654 11.121 1.128 16.275 9.205 16.393 9.308l68.253 91.798a7.487 7.487 0 006.025 3.025c6.089 0 9.715-6.998 6.014-11.976L120.23 145.138c-7.48-10.06-6.135-24.148 3.063-32.073 10.362-8.927 25.938-7.154 34.018 3.712l92.621 124.573a7.487 7.487 0 006.025 3.025 7.5 7.5 0 006.013-11.975C253.8 221.407 158.606 93.373 153.174 86.066c-7.825-10.523-5.477-25.424 5.307-33.021 10.247-7.214 24.856-4.645 32.568 5.727l114.287 153.713a7.5 7.5 0 0010.494 1.544 7.497 7.497 0 001.545-10.493zm-214.326 47.012c.073.097.054.072 0 0z" />
  </svg>
)
